import React from 'react'
import { Helmet } from 'react-helmet'
import { usePathname, useQueryString } from '@core/navigation'

/* Global Config */
import { domain, domainNoPath, siteName, ogLogo } from '@lib/config/global'

/* Components */
import MetaTags from '@components/base/MetaTags/MetaTags'

/* Helpers */
import { getPlainText } from '@helpers/stringHelper'

/* Local Types */
export type metaType = {
	title?: string
	description?: string
	keywords?: string
	[key: string]: string | undefined
}
export type ogType = {
	title?: string
	description?: string
	image?: string
	cropImage?: boolean
}
type verificationsType = {
	[name: string]: string
}
export type pageMetasType = {
	pageTitle?: string
	pageDescription?: string
	pageKeywords?: string
	[key: string]: string | undefined
}

/* Default Meta */
const useMetas = (meta: metaType, pageMetas?: pageMetasType): metaType => {
	const defaultMeta: metaType = {}
	if (meta) {
		Object.keys(meta).forEach((key) => {
			meta[key] = getPlainText(meta[key] || '')
		})
	}
	if (pageMetas) {
		;['pageTitle', 'pageDescription', 'pageKeywords'].forEach((key) => {
			if (pageMetas[key]) {
				defaultMeta[key.replace('page', '').toLowerCase()] = getPlainText(
					pageMetas[key]
				)
			}
		})
	}
	return Object.assign(defaultMeta, meta || {})
}

/* Wrap OG Image in expected params? */
const cropOGImage = (image: string): string => {
	return image
		? `${image.split('?')[0]}?auto=format,compress&w=1200&h=630&fit=crop`
		: ''
}

/* Fixed Site Verifications */
const verifications: verificationsType = {
	'facebook-domain-verification': '2168ylbjs2bqxd2pdlajejabp1qfgb',
	'p:domain_verify': 'ad9f32857a967c1d492bcfc2df59c312',
	'wot-verification': 'dc0f62f82f0c7688c5cf',
	'google-site-verification': 'FOJ3t1Tw2feyl1vaWQnSbqtplwhFc-onBzDY4_6F824',
	'fb:app_id': '282647099973572',
}

/* Empty to skip twitter handle - no twitter for 1916 */
const twitterHandle: string = '' //'@watchbox_global';

const Page = ({
	children,
	meta,
	og,
	canonical,
	pageMetas,
}: {
	children: React.ReactNode
	meta?: metaType
	og?: ogType
	canonical?: string
	pageMetas?: pageMetasType
}): React.ReactElement => {
	const pathname = usePathname()
	const queryString = useQueryString()

	/* Locale - en-US only for now */
	const locale: string = 'en-US' //useLocale()

	/* Calculated Metas */
	meta = useMetas(meta || {}, pageMetas || {})

	/* Crop Image? */
	let ogImage: string = og?.image?.toString() || ogLogo || ''
	if (og?.cropImage) {
		ogImage = cropOGImage(ogImage)
	}

	/* OG */
	const ogFinal: ogType = Object.assign({
		title: getPlainText(og?.title?.toString() || meta?.title?.toString() || ''),
		description: getPlainText(
			og?.description?.toString() || meta?.description?.toString() || ''
		),
		image: ogImage || '',
	})

	/* Exclude Request Processor Cache */
	const queryStringParams = new URLSearchParams(queryString)
	queryStringParams.delete('cache')
	const safeQueryString = queryStringParams.toString()

	/* Full Canonical URL */
	const canonicalUrl: string = `${domainNoPath}${canonical || pathname}`

	/* Full URL */
	const url: string = `${domainNoPath}${pathname}${
		safeQueryString ? `?${safeQueryString}` : ''
	}`

	/* Remove trailing empty query from URLs */
	const safeUrl: string = url.replace(/\?{1,3}$/, '')
	const safeCanonicalUrl: string = canonicalUrl.replace(/\?{1,3}$/, '')

	/* Hreflangs */
	const hrefLangs: {
		[key: string]: string
	} = {
		[locale.replace('_', '-')]: safeUrl,
		[locale.substring(0, 2)]: safeUrl,
		'x-default': safeUrl,
	}

	return (
		<>
			<MetaTags
				title={meta.title}
				keywords={meta.keywords}
				description={meta.description}
			/>
			<Helmet>
				{/* Canonical */}
				<link rel={`canonical`} href={safeCanonicalUrl} />

				{/* Open Graph */}
				<meta property="og:url" content={safeUrl} />
				<meta property="og:site_name" content={siteName} />
				<meta property="og:locale" content={locale.replace('-', '_')} />
				<meta property="og:type" content="website" />
				{ogFinal.title && <meta property="og:title" content={ogFinal.title} />}
				{ogFinal.description && (
					<meta property="og:description" content={ogFinal.description} />
				)}
				{ogFinal.image && <meta property="og:image" content={ogFinal.image} />}
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta property="og:image:alt" content={siteName} />

				{/* Twitter */}
				<meta name="twitter:card" content="summary" />
				{twitterHandle && <meta name="twitter:site" content={twitterHandle} />}
				{twitterHandle && (
					<meta name="twitter:creator" content={twitterHandle} />
				)}
				{ogFinal.title && <meta name="twitter:title" content={ogFinal.title} />}
				{ogFinal.description && (
					<meta name="twitter:description" content={ogFinal.description} />
				)}
				{ogFinal.image && <meta name="twitter:image" content={ogFinal.image} />}
				<meta name="twitter:image:alt" content={siteName} />

				{/* Apple */}
				<meta
					name="apple-itunes-app"
					content={`app-id=738072910, app-argument=${domain}`}
				/>

				{/* Verifications */}
				{Object.entries(verifications).map(([name, content]) => {
					return name == 'fb:app_id' ? (
						<meta key={name} property={name} content={content} />
					) : (
						<meta key={name} name={name} content={content} />
					)
				})}

				{/* Robots */}
				<meta name="robots" content="index, follow" />
				<meta name="robots" content="max-image-preview:large" />

				{/* Hreflang */}
				{Object.entries(hrefLangs).map(([lang, href]) => {
					return <link key={lang} rel="alternate" href={href} hrefLang={lang} />
				})}
			</Helmet>
			{children}
		</>
	)
}

export default Page
